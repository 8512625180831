(function () {

    function installProductSlider() {

        typecaseSliderInstall('.wp-block-hendersons-product-slider .multi-slider', 'li');

    }
    
    if (document.readyState === "complete" || document.readyState === "loaded") {
        installProductSlider();
    } else {
        document.addEventListener( 'DOMContentLoaded', installProductSlider );
    }

    document.addEventListener("typecase|slide-changed", function (e) {
        
        var data = e.detail,
            firstSlide,
            lastSlide;

        if (! data.slider.classList.contains("multi-slider"))
            return;

        if (data.slide.nextElementSibling === null 
         || data.slide.nextElementSibling.nextElementSibling === null
         || data.slide.nextElementSibling.nextElementSibling.nextElementSibling === null) {

            firstSlide = data.slider.querySelector(data.slider.getAttribute("data-slide-selector") 
                            + ':first-of-type');
            
            lastSlide = data.slider.querySelector(data.slider.getAttribute("data-slide-selector") 
                + ':last-of-type');

            lastSlide.insertAdjacentElement('afterend', firstSlide);
        }
    });

}());