(function () {

    

    function installTaskWidget() {

        typecaseSliderInstall('.wp-block-hendersons-task-widget', 'li');

        var buttons = document.querySelectorAll('.wp-block-hendersons-task-widget nav button');

        for(var i = 0; i < buttons.length; i += 1) {
            if (buttons[i].classList.contains('prev')) {
                buttons[i].innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"/></svg>';
            } else {
                buttons[i].innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"/></svg>';
            }
        }

    }

    if (document.readyState === "complete" || document.readyState === "loaded") {
        installTaskWidget();
    } else {
        document.addEventListener( 'DOMContentLoaded', installTaskWidget );
    }

}());